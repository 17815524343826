import { FormOutlined, LogoutOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Space } from 'antd'
import { useContext, FC, memo, useCallback, useState } from 'react'
import type { MenuInfo } from 'rc-menu/lib/interface'
import globalContext from '~/contexts/global.context'
// import styles from './index.module.less'
import { useHistory } from 'react-router-dom'
import { routeNames } from '~/routes/const'
import { IUserInfoProps } from './const'
import UpdatePasswordModal from './update-password-modal'
import avatar from '~/assets/images/default_avatar.png'

const Component: FC<IUserInfoProps> = (props) => {
  const { name, dispatch } = useContext(globalContext)
  const history = useHistory()
  const [visible, setVisible] = useState(false);

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event
      if (key === 'logout') {
        window.localStorage.removeItem('Authorization')
        dispatch({ name: '' })
        history.replace(routeNames.login)
        return
      }
    },
    [history]
  )

  const handleLogoutClick = async () => {
    // 调用注销登录接口
    try {
      window.localStorage.removeItem('Authorization')
      dispatch({ name: '' })
      history.replace(routeNames.login)
    } catch (error) { }
  }


  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="update-password" onClick={() => setVisible(true)}>
        <FormOutlined />
        修改密码
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menuHeaderDropdown}>
        <Space>
          <Avatar src={avatar} />
          <span>{name}, 您好</span>
        </Space>
      </Dropdown>
      <UpdatePasswordModal visible={visible} setVisible={setVisible} onLogout={handleLogoutClick} />
    </>
  )
}

Component.defaultProps = {}
Component.displayName = 'UserInfo'

const UserInfo = memo(Component)
export default UserInfo
