import { Form, Input, message, Modal } from "antd";
import React, { FC, memo, useCallback } from "react";
import { api } from "~/request";
import { put } from "../request-mock";

interface IUpdatePasswordModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onLogout: () => void;
}

const formItemLayout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 10
  }
};

const UpdatePasswordModal: FC<IUpdatePasswordModalProps> = ({ visible, setVisible, onLogout }) => {
  const [form] = Form.useForm();

  const handleOk = useCallback(async () => {
    const { newPassword, password } = await form.validateFields();
    await api['/admin/auth/resetPwd_PUT']({ password, newPassword })
    handleCancel();
    message.success('密码修改成功，请重新登录');
    onLogout();
  }, [form])

  const handleCancel = useCallback(() => {
    form.resetFields();
    setVisible(false);
  }, [form])

  return (
    <Modal title="修改密码" visible={visible} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} {...formItemLayout}>
        <Form.Item label="原密码" name="password" rules={[{ required: true, message: '请输入密码' }, { min: 6, max: 12, message: '密码必须6~12位' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item label="新密码" name="newPassword" rules={[{ required: true, message: '请输入新密码' }, { min: 6, max: 12, message: '密码必须6~12位' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item label="确认密码" name="confirmPassword" rules={[{ required: true, message: '请输入新密码' }, ({ getFieldValue }) => ({
          validator(_rule, value) {
            return !value || getFieldValue('newPassword') === value
              ? Promise.resolve()
              : Promise.reject(new Error('密码不一致'))
          }
        })]}>
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default memo(UpdatePasswordModal);
