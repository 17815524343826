const { REACT_APP_ENV } = process.env
export const isDevelopment = REACT_APP_ENV === 'development'
export const isUat = REACT_APP_ENV === 'uat'
export const apiUrl = isDevelopment ? 'https://sappadmin.oebiotech.com/' : isUat ? 'https://sappadmin.oebiotech.com/' : 'https://sappadmin.oebiotech.com/'
// export const apiUrl = `http://47.103.40.50:3071`
// "homepage": "http://yanfa-oss.f.wmeimob.com/webtest/dongfanglong-shop-backend/1003333/"
export const aliyunOssTokenUrl = `/admin/oss/info`

export const defaultPwd = 'ocj123456' // 默认密码

export const publicPath = '/'

export const routeType = 'hash' // 路由类型 history | hash

/**
 * 是否跳过权限验证
 * @warning 允许在接口无法调用时继续本地开发.只允许在本地开发时使用
 */
export const skipAuth = process.env.NODE_ENV === 'development' && false

/**
 * 配置栏配置属性
 *
 * @description 请从配置栏目中拷贝替换
 */
export const systemConfig: SystemInfo = {
  layoutSetting: {
    menuTheme: 'dark',
    headerTheme: 'light'
  },
  breadcrumb: {
    type: 'normal'
  }
}
