import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.userList,
    component: () => import('../../pages/user'),
    meta: {
      title: '用户列表'
      , code: EAuthCode.USERMANAGEMENT_USERLIST
    }
  }
] as TRoutes[]