import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.systemSet,
    component: () => import('../../pages/departmentManagement/systemSet'),
    meta: {
      title: '系统设置'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_SYSTEMSET

    }
  },
  {
    path: routeNames.operationLog,
    component: () => import('../../pages/departmentManagement/operationLog'),
    meta: {
      title: '操作日志'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_OPERATIONLOG

    }
  },
  {
    path: routeNames.departmentManagement,
    component: () => import('../../pages/departmentManagement'),
    meta: {
      title: '部门管理'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_DEPT

    }
  },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/departmentManagement/employeeManagement'),
    meta: {
      title: '员工管理'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_STAFF
    }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/departmentManagement/roleManagement'),
    meta: {
      title: '角色管理'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_ROLE
    }
  }
] as TRoutes[]