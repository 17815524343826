import { createBrowserHistory, createHashHistory } from 'history'
import iconFile from './images/icon_file.jpg';
import { generateRouteAndMenu, IRoute, routeNames } from './const'
import BasicLayout from '~/layouts/basicLayout'
import MMPNoAuth from '~/pages/403'

import generate from './modules/generates'
import _example from './modules/_example'
import departmentManagement from './modules/departmentManagement'
import basicSetting from './modules/basicSetting'
import categoryStorage from './modules/categoryStorage'
import decorationSetting from './modules/decorationSetting'
import projectManagement from './modules/projectManagement'
import userManagement from './modules/userManagement'

import Login from '../pages/login'
import { AppstoreOutlined, BgColorsOutlined, ClusterOutlined, FundOutlined, SettingOutlined, TableOutlined } from '@ant-design/icons'
import { routeType } from '~/config';

export const history = routeType === 'hash' ? createHashHistory() : createBrowserHistory()

export const routers: IRoute[] = [
  { path: routeNames.login, component: Login, meta: { title: '登录', sync: true, noLogin: true } },
  {
    path: '/',
    layout: BasicLayout,
    children: [
      // { name: '模板', path: '/_example', icon: FundOutlined, children: _example },
      // { name: '自动表格', path: '/generate', icon: TableOutlined, iconImg: iconFile, children: generate },
      // { name: '基础设置', path: '/basicSetting', icon: SettingOutlined, children: basicSetting },
      { name: '系统管理', path: '/departmentManagement', icon: AppstoreOutlined, children: departmentManagement },
      // { name: '类目管理', path: '/categoryStorage', icon: ClusterOutlined, children: categoryStorage },
      // { name: '装修设置', path: '/decorationSetting', icon: BgColorsOutlined, children: decorationSetting },
      { name: '用户管理', path: '/decorationSetting', icon: BgColorsOutlined, children: userManagement },
      { name: '项目管理', path: '/decorationSetting', icon: BgColorsOutlined, children: projectManagement },
      { path: routeNames[403], component: MMPNoAuth, meta: { sync: true, noLogin: true } }
      // { path: '*', component: MMPNOMatch, meta: { sync: true, noLogin: true } }
    ]
  }
]

const { routes } = generateRouteAndMenu(routers)

export function getMenus() {
  return generateRouteAndMenu(routers).menus;
}

export { routes }
