/**
 * 全局上下文。在这里你可以存放一些全局的变量数据
 */
import { RouteBreadCrumb } from 'MMRouter';
import React from 'react';
import { TMenu } from '~/data-model/common';

type Key = keyof Omit<IGlobalContext, 'dispatch'>;
type Value = IGlobalContext[Key];

export interface IDispatch {
  (key: Key | Partial<Omit<IGlobalContext, 'dispatch'>>, value?: Value): void
}

export interface IGlobalContext extends
  Pick<SystemInfo,

  'layoutSetting' |

  'breadcrumb'
  > {
  /** 菜单路由面包屑 */
  breadcrumbs: RouteBreadCrumb[];
  /** 用户名 */
  name: string,
  /** 应用名称 */
  applicationName: string;
  /** 菜单栏 */
  menus: TMenu[];
  /** 权限code */
  authCodes: string[];
  /** 数据修改 */
  dispatch: IDispatch
  /** 异步action */
  dispatchAction: (action: string, payload?: any) => Promise<any>
}

const globalContext = React.createContext({} as IGlobalContext);
export default globalContext;
